import dynamic from "next/dynamic";
import { useState, useEffect } from "react";
// By default it provides SSR...
import Layout from "../hoc/staticLayout"; //dynamic(() => import('../hoc/staticLayout'),{loading: () => <></>,ssr: true});
import BannerCarousal from "../components/static/landingPage/Banner"; //dynamic(() => import('../components/static/landingPage/Banner'), { loading: () => <></>, ssr: true });
import SubBannerCarousal from "../components/static/landingPage/SubBanner"; //dynamic(() => import('../components/static/landingPage/SubBanner'), { loading: () => <></>, ssr: true });
import Media from "../components/static/landingPage/Media"; //dynamic(() => import('../components/static/landingPage/Media'), { loading: () => <></>, ssr: true });
import Classes from "../components/static/landingPage/Classes"; //dynamic(() => import('../components/static/landingPage/Classes'), { loading: () => <></>, ssr: true });
import Faculty from "../components/static/landingPage/Faculty"; //dynamic(() => import('../components/static/landingPage/Faculty'), { loading: () => <></>, ssr: true });
import Testimony from "../components/static/landingPage/Testimony"; //dynamic(() => import('../components/static/landingPage/Testimony'), { loading: () => <></>, ssr: true });
const RequestInfoModal = dynamic(
  () => import("../components/common/requestInfoModal"),
  { loading: () => <></>, ssr: false }
);
import { getFacultiesData } from "../api/faculty";
import { fetchClasses } from "../api/class";
import { fetchTestimonies } from "../api/testimony";
import { getAllPrograms, getAllHeardSources } from "../api/register";
import { hotjar } from "react-hotjar";
import { withUserAgent } from "next-useragent";
import { getNewsEventPageData } from "../api/news_event";
import { getLandingPageData } from "../api/home";
import { getBanners } from "../api/banners";
// import MessengerCustomerChat from 'react-messenger-customer-chat';

function LandingPage(props) {
  const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false);
  const [responseData, setResponseData] = useState(false);

  const handleRequestInfo = () => {
    setOpenRequestInfoModal(true);
  };
  const closeModals = () => {
    setOpenRequestInfoModal(false);
  };
  useEffect(() => {
    if (props.ua?.source.toLowerCase().indexOf("lighthouse") === -1) {
      process.env.NODE_ENV === "production" &&
        process.browser &&
        hotjar.initialize(1788224, 6);
    }
  }, []);

  const meta = {
    title: "Welcome to Bayan | Bayan Islamic Graduate School",
    description:
      "Be a change agent in your community! Accredited Masters Degrees In Islamic Studies, Leadership, Theology; M.Div. Islamic Chaplaincy, or a Doctor of Ministry in Islamic Leadership. Access Over 300 Hours of Course Content Now Through Bayan On-Demand!",
    route: "",
    image_url: `${process.env.IMAGES_BASE_URL}/static-content/landing_page_2.jpg`,
  };
  return (
    <>
      <Layout theme={props.theme} meta={meta}>
        <>
          {openRequestInfoModal && (
            <RequestInfoModal closeModals={closeModals} />
          )}
        </>
        <BannerCarousal
          showRequestInfo={handleRequestInfo}
          theme={props.theme}
          bannersData={props?.bannersData}
        />
        <SubBannerCarousal
          theme={props.theme}
          sub_banner={props?.landingPageData?.[0]?.sub_banner}
        />
        {/*<Faculty theme={props.theme} faculties={props.faculties} />*/}
        <Faculty
          theme={props.theme}
          faculties={props.faculties}
          wc_faculty={props?.landingPageData?.[0]?.wc_faculty}
        />
        <Testimony
          theme={props.theme}
          testimonies={props.testimonies}
          testimony={props?.landingPageData?.[0]?.testimony}
        />
        <Classes
          theme={props.theme}
          classes={props.classes}
          bayan_on_demand={props?.landingPageData?.[0]?.bayan_on_demand}
        />
        <Media
          theme={props.theme}
          newsEventPageData={props?.newsEventPageData ?? ""}
        />
      </Layout>
    </>
  );
}

export async function getServerSideProps(context) {
  // You can use any data fetching library
  const allFaculties = await getFacultiesData();
  const faculties =
    allFaculties && allFaculties["faculty"] ? allFaculties.faculty : [];
  const classes = await fetchClasses();
  const testimonies = await fetchTestimonies();
  const programs = await getAllPrograms();
  const heardSources = await getAllHeardSources();
  const newsEventPageData = await getNewsEventPageData();
  const landingPageData = await getLandingPageData();
  const bannersData = await getBanners();
  return {
    props: {
      faculties: faculties || null,
      classes: classes || null,
      testimonies: testimonies || null,
      programs: programs || null,
      sources: heardSources || null,
      newsEventPageData: newsEventPageData ?? "",
      landingPageData: landingPageData ?? "",
      bannersData: bannersData ?? "",
    },
    // revalidate: 5*60, // seconds
  };
}

export default withUserAgent(LandingPage);
